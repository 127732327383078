import i18n from "#/locales";
import qs from "qs";
import axios from "@/libvue/api/axios.js";
import Config from "#/config";
import * as constants from "./actions";
import { GET_LINEUP_SUCCESS } from "@/libvue/apps/catalog/lineups/actions";

import routes from "#/routes";

function getDefaultState() {
  return {
    detail: {
      data: { group_item: { group_type: "", name: "", slug: "" } },
      status: "",
      errors: {},
      emptyFeedback: "no results"
    },
    list: {
      data: { results: [], current: 1, next: null, count: null },
      status: "",
      errors: {},
      emptyFeedback: "no results",
      initialCount: null
    },
    featuredList: {
      data: { results: [], current: 1, next: null, count: null },
      status: "",
      errors: {},
      emptyFeedback: "no results"
    },
    aggs: {
      data: {},
      status: "",
      errors: {},
      filters: {
        age_groups: null,
        categories: null,
        courses: null,
        closed_captions: null,
        curricula: null,
        language: null,
        item_type: null,
        show: null,
        subjects: null,
        channels: null,
        group_slug: null,
        group_type: null
      }
    },
    ageGroups: {
      data: [],
      status: null,
      errors: null
    },
    teamAgeGroups: {
      data: [],
      status: null,
      errors: null
    },
    filterParams: {
      age_groups: null,
      categories: null,
      courses: null,
      search: null,
      show: null,
      group_slug: null,
      group_type: null,
      ordering: "-published_on",
      page: 1,
      page_size: null
    },
    userFilters: {
      age_groups: null,
      categories: null,
      published_on: null,
      courses: null,
      closed_captions: null,
      curricula: null,
      language: null,
      item_type: null,
      subjects: null,
      show: null,
      channels: null
    },
    by: {
      curricula: {},
      categories: {},
      age_groups: {},
      show: {},
      owner: {},
      channels: {},
      latest: {},
      featured: {},
      group_slug: {}
    },
    auto: {
      selected: null,
      q: null,
      data: [],
      status: null,
      errors: null
    },
    channels: {
      data: { results: [] },
      status: "",
      errors: {}
    },
    channel: {
      data: {},
      status: "",
      errors: {}
    },
    themes: {
      data: { results: [] },
      status: "",
      errors: {}
    },
    theme: {
      data: {},
      status: "",
      errors: {}
    },
    themesBy: {},
    group: {
      data: { results: [] },
      status: "",
      errors: {}
    },
    stats: {
      screenerIsPlaying: null
    },
    upNext: {
      data: { results: [] },
      track: false
    },
    mGet: {
      data: {},
      status: "",
      errors: {}
    },
    seo: {
      data: {},
      status: "",
      errors: {}
    }
  };
}

const getters = {
  getItemDetail: (state) => state.detail.data,

  getTeamAgeGroupNames: (state) => {
    return state.teamAgeGroups.data.map((group) => group.name);
  },

  //Level or Audience
  getTeamTypeAudience: (state) => {
    return state.teamAgeGroups.data[0]?.age_type;
  },
  //For breadcrumbs (item detail) -- to delete eventually --
  getItemAudience: (state) => (item) => {
    const mapDetailAgesUid = item
      ? item.ages
          ?.filter((a) => a.age_type == state.teamAgeGroups.data[0]?.age_type)
          .map((group) => group.uid)
      : state.detail.data.ages
          .filter((a) => a.age_type == state.teamAgeGroups.data[0]?.age_type)
          .map((group) => group.uid);
    const audience = state.ageGroups.data.find((group) =>
      mapDetailAgesUid?.includes(group.uid)
    );

    return audience || {};
  },
  //List audience names (long version)
  getItemAudiencesLong: (state) => (item) => {
    const mapDetailAgesUid = item.ages
      ?.filter((a) => a.age_type == state.teamAgeGroups.data[0]?.age_type)
      .map((group) => group.uid);

    const audiences = state.teamAgeGroups.data.filter((group) =>
      mapDetailAgesUid?.includes(group.uid)
    );
    //ex.: Elementary (4 to 7), Secondary
    const longListAudiences = audiences.map((group) => group.name).join(", ");

    return longListAudiences;
  },

  //List audience names (short version for unit)
  getItemAudiencesShort: (state) => (item) => {
    const teamAgeGroupsUid = state.teamAgeGroups.data.map((group) => group.uid);

    const itemAgeGroups = item.ages?.filter((group) =>
      teamAgeGroupsUid.includes(group.uid)
    );

    const itemAgeGroupsName = itemAgeGroups?.map((group) => group.name);

    //Ex.: elementary-4-7_secondary
    const translationKey = itemAgeGroupsName?.sort().join("_");

    return translationKey;
  },

  //Get specific audience by uid
  getAudience: (state) => (uid) => {
    return state.teamAgeGroups.data.find((group) => group.uid == uid);
  },

  getChannel: (state) => (uid) => {
    return state.channels.data.results.find((channel) => channel.uid == uid);
  },
  getThemes: (state) => (uid) => {
    return state.themes.data.results.find((theme) => theme.uid == uid);
  },
  getTheme: (state) => (uid) => {
    return uid && state.theme.data.uid == uid ? state.theme.data : null;
  },
  getFilterParams: (state) => {
    return Object.keys(state.filterParams)
      .filter((k) => state.filterParams[k] != null)
      .reduce((a, k) => ({ ...a, [k]: state.filterParams[k] }), {});
  },
  getUserFilters: (state) => {
    return Object.keys(state.userFilters)
      .filter((k) => state.userFilters[k] != null)
      .filter((k) => state.userFilters[k] != [])
      .filter((k) => state.userFilters[k] != "")
      .reduce((obj, key) => {
        obj[key] = state.userFilters[key];
        return obj;
      }, {});
  },
  getAggsFilters: (state) => {
    return Object.keys(state.aggs.filters)
      .filter((k) => state.aggs.filters[k] != null)
      .filter((k) => state.aggs.filters[k] != [])
      .filter((k) => state.aggs.filters[k] != "")
      .reduce((obj, key) => {
        obj[key] = state.aggs.filters[key];
        return obj;
      }, {});
  },
  formatUserFilters: (state, getters) => {
    let payload = {};
    for (const [key, value] of Object.entries(getters.getUserFilters)) {
      payload[key] = value.map((v) => v.value).flat();
    }
    return payload;
  },
  formatAggsFilters: (state, getters) => {
    let payload = {};
    for (const [key, value] of Object.entries(getters.getAggsFilters)) {
      payload[key] = value.map((v) => v.value).flat();
    }

    return {
      ...getters.getFilterParams,
      ...getters.formatUserFilters,
      ...payload
    };
  }
};

const actions = {
  [constants.ITEM_GET]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.ITEM_GET);
      axios
        .get(`items/${params.uid}`)
        .then(({ data }) => {
          const localdata = {
            ...data,
            metadata: {
              categories: data.categories?.map((c) => c.name)
            },
            object_name: `${data.language} | ${data.external_ref} | ${data.title} | ${data.content_owner?.name}`
          };
          commit(constants.ITEM_GET_SUCCESS, localdata);
          resolve(data);
        })
        .catch((error) => {
          commit(constants.ITEM_GET_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.ITEM_GET_LIST]: ({ commit, getters }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.ITEM_GET_LIST);
      const filterParams = { ...getters.getFilterParams };

      const payload = getters.formatUserFilters
        ? { ...filterParams, ...getters.formatUserFilters }
        : filterParams;

      const baseUrl = params?.url ? params.url : "items";
      const method = params?.method ? params.method : "post";

      const localParams =
        method == "post"
          ? payload
          : {
              params: payload,
              paramsSerializer: (p) => {
                return qs.stringify(p, {
                  arrayFormat: "comma"
                });
              }
            };

      axios[method](baseUrl, localParams)
        .then((response) => {
          commit(constants.SET_ITEM_INITIAL_COUNT, {
            response,
            filters: getters.getUserFilters
          });
          if (response.data?.lineup_items) {
            commit(GET_LINEUP_SUCCESS, response);
          }
          commit(constants.ITEM_GET_LIST_SUCCESS, response);

          resolve(response);
        })
        .catch((error) => {
          commit(constants.ITEM_GET_LIST_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.ITEM_MGET]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.ITEM_MGET);
      axios
        .post(`items/mget`, { ...params })
        .then((response) => {
          commit(constants.ITEM_MGET_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.ITEM_MGET_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.ITEM_GET_FEATURED_LIST]: ({ commit, getters }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.ITEM_GET_FEATURED_LIST);
      const filterParams = getters.getFilterParams;

      const payload = {
        ...filterParams,
        featured: true,
        ordering: "-published_on",
        page: 1,
        page_size: 8
      };

      const baseUrl = params?.url ? params.url : "items";

      axios
        .post(baseUrl, { ...payload })
        .then((response) => {
          commit(constants.ITEM_GET_FEATURED_LIST_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.ITEM_GET_FEATURED_LIST_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.ITEM_GET_LIST_BY_FACET]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.ITEM_GET_LIST_BY_FACET, params);

      axios
        .post(`items`, { ...params })
        .then((response) => {
          commit(constants.ITEM_GET_LIST_BY_FACET_SUCCESS, {
            response,
            params
          });
          resolve(response);
        })
        .catch((error) => {
          commit(constants.ITEM_GET_LIST_BY_FACET_ERROR, { error, params });
          reject(error);
        });
    });
  },
  [constants.GET_THEME_ITEMS_BY_FACET]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.GET_THEME_ITEMS_BY_FACET, params);

      axios
        .get(`themes/${params.theme_uid}/items`, { params })
        .then((response) => {
          commit(constants.GET_THEME_ITEMS_BY_FACET_SUCCESS, {
            response,
            params
          });
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_THEME_ITEMS_BY_FACET_ERROR, { error, params });
          reject(error);
        });
    });
  },
  [constants.ITEM_GET_AGGS]: ({ commit, getters }) => {
    return new Promise((resolve, reject) => {
      commit(constants.ITEM_GET_AGGS);

      const advancedSearchMode = JSON.parse(
        localStorage.getItem("advanced-search-mode")
      );

      const baseUrl =
        routes.currentRoute.value.name == "catalog.themes.detail"
          ? `themes/${routes.currentRoute.value.params.uid}/items`
          : "items";

      let localFilters = null;
      if (advancedSearchMode) {
        delete getters.formatAggsFilters.search;

        localFilters = {
          ...getters.formatAggsFilters,
          page_size: 1
        };
      } else {
        const q = getters.formatAggsFilters.query;
        delete getters.formatAggsFilters.query;

        localFilters = {
          ...getters.formatAggsFilters,
          search: getters.formatAggsFilters.search || q,
          page_size: 1
        };
      }

      axios
        .post(baseUrl, localFilters)
        .then((response) => {
          commit(constants.ITEM_GET_AGGS_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.ITEM_GET_AGGS_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.GET_AGE_GROUPS]: ({ commit }, age_type) => {
    return new Promise((resolve, reject) => {
      commit(constants.GET_AGE_GROUPS);
      axios
        .get(`age-groups`, { params: { age_type } })
        .then((response) => {
          commit(constants.GET_AGE_GROUPS_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_AGE_GROUPS_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.GET_TEAM_AGE_GROUPS]: ({ commit, getters }) => {
    return new Promise((resolve, reject) => {
      const team_uid = getters.getTeamUID;
      commit(constants.GET_TEAM_AGE_GROUPS);
      axios
        .get(`teams/${team_uid}/age-groups`)
        .then((response) => {
          commit(constants.GET_TEAM_AGE_GROUPS_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_TEAM_AGE_GROUPS_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.GET_ITEMS_AUTOCOMPLETE]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.GET_ITEMS_AUTOCOMPLETE);
      axios
        .get(`items/autocomplete`, { params })
        .then((response) => {
          commit(constants.GET_ITEMS_AUTOCOMPLETE_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_ITEMS_AUTOCOMPLETE_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.ITEMS_APPEND_NEXT]: ({ commit, getters }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.ITEMS_APPEND_NEXT);

      const filterParams = getters.getFilterParams;

      let payload = getters.formatUserFilters
        ? {
            ...filterParams,
            ...getters.formatUserFilters
          }
        : { ...filterParams };

      const baseUrl = params?.url ? params.url : "items";

      axios
        .post(baseUrl, { ...payload, page: params.next_page_number })
        .then((response) => {
          commit(constants.ITEMS_APPEND_NEXT_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.ITEMS_APPEND_NEXT_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.ITEMS_APPEND_NEXT_THEMES]: ({ commit, getters }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.ITEMS_APPEND_NEXT);
      commit(constants.ITEMS_APPEND_NEXT_THEMES);

      const filterParams = { ...getters.getFilterParams, ...params };
      const payload = getters.formatUserFilters
        ? { ...filterParams, ...getters.formatUserFilters }
        : filterParams;

      const localParams = {
        params: payload,
        paramsSerializer: (p) => {
          return qs.stringify(p, {
            arrayFormat: "comma"
          });
        }
      };

      axios
        .get(`themes`, localParams)
        .then((response) => {
          commit(constants.ITEMS_APPEND_NEXT_SUCCESS, response);
          commit(constants.ITEMS_APPEND_NEXT_THEMES_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.ITEMS_APPEND_NEXT_ERROR, error);
          commit(constants.ITEMS_APPEND_NEXT_THEMES_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.GET_CHANNELS]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit(constants.GET_CHANNELS);

      axios({ url: `channels` })
        .then((response) => {
          commit(constants.GET_CHANNELS_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_CHANNELS_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.GET_CHANNEL]: ({ commit }, uid) => {
    return new Promise((resolve, reject) => {
      commit(constants.GET_CHANNEL);

      axios({ url: `channels/${uid}` })
        .then((response) => {
          commit(constants.GET_CHANNEL_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_CHANNEL_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.GET_CHANNEL_ITEMS]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.GET_CHANNEL_ITEMS, params);
      axios
        .get(`channels/${params.uid}/items`)
        .then((response) => {
          commit(constants.GET_CHANNEL_ITEMS_SUCCESS, { response, params });
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_CHANNEL_ITEMS_ERROR, { error, params });
          reject(error);
        });
    });
  },
  [constants.GET_THEMES]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit(constants.GET_THEMES);
      axios
        .get(`themes`)
        .then((response) => {
          commit(constants.GET_THEMES_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_THEMES_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.GET_THEME]: ({ commit }, theme_uid) => {
    return new Promise((resolve, reject) => {
      commit(constants.GET_THEME);
      axios
        .get(`themes/${theme_uid}`)
        .then((response) => {
          commit(constants.GET_THEME_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_THEME_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.GET_GROUP]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.GET_GROUP);
      axios
        .get(`groups`, { params })
        .then((response) => {
          commit(constants.GET_GROUP_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_GROUP_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.GET_GROUP_APPEND_NEXT]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.GET_GROUP_APPEND_NEXT);

      axios
        .get(`groups`, { params })
        .then((response) => {
          commit(constants.GET_GROUP_APPEND_NEXT_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_GROUP_APPEND_NEXT_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.GET_JSON_LD]: ({ commit }, params) => {
    const parser = new DOMParser();

    return new Promise((resolve, reject) => {
      commit(constants.GET_JSON_LD);
      axios({
        baseURL: Config.seoBase(),
        url: params.url,
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then((response) => {
          const doc = parser.parseFromString(response.data, "text/html");
          const el = doc.querySelector('script[type="application/ld+json"]');

          if (el) {
            const data = JSON.parse(el.innerHTML);
            commit(constants.GET_JSON_LD_SUCCESS, data);
          }
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_JSON_LD_ERROR, error);
          reject(error);
        });
    });
  }
};

const mutations = {
  [constants.ITEM_GET]: (state) => {
    state.detail.status = "fetching";
    state.detail.errors = {};
  },
  [constants.ITEM_GET_SUCCESS]: (state, data) => {
    state.detail.status = "success";
    state.detail.errors = {};
    state.detail.data = data;
  },
  [constants.ITEM_GET_ERROR]: (state, err) => {
    state.detail.status = "error";
    state.detail.errors = err;
  },
  [constants.ITEM_GET_LIST]: (state) => {
    state.list.status = "fetching";
    state.list.errors = {};
    state.list.data.results = [];
  },
  [constants.ITEM_GET_LIST_SUCCESS]: (state, { data }) => {
    state.list.status = "success";
    state.list.errors = {};
    state.list.data = data;

    if (data.lineup_items) {
      state.list.data = {
        aggregations: null,
        count: data.lineup_items.length,
        current: 1,
        end_index: 1,
        next: null,
        next_page_number: null,
        page_count: null,
        page_size: data.lineup_items.length,
        previous: null,
        previous_page_number: null,
        results: data.lineup_items,
        start_index: null,
        suggest: null
      };
    }
  },
  [constants.ITEM_GET_LIST_ERROR]: (state, err) => {
    state.list.status = "error";
    state.list.errors = err;
  },
  [constants.ITEM_MGET]: (state) => {
    state.mGet.status = "fetching";
    state.mGet.errors = {};
  },
  [constants.ITEM_MGET_SUCCESS]: (state, { data }) => {
    state.mGet.status = "success";
    state.mGet.errors = {};
    state.mGet.data = data;
  },
  [constants.ITEM_MGET_ERROR]: (state, err) => {
    state.mGet.status = "error";
    state.mGet.errors = err;
  },
  [constants.ITEM_GET_FEATURED_LIST]: (state) => {
    state.featuredList.status = "fetching";
    state.featuredList.errors = {};
    state.featuredList.data.results = [];
  },
  [constants.ITEM_GET_FEATURED_LIST_SUCCESS]: (state, { data }) => {
    state.featuredList.status = "success";
    state.featuredList.errors = {};
    state.featuredList.data = data;
  },
  [constants.ITEM_GET_FEATURED_LIST_ERROR]: (state, err) => {
    state.featuredList.status = "error";
    state.featuredList.errors = err;
  },
  [constants.ITEM_GET_LIST_BY_FACET]: (state, params) => {
    state.by[params.facet] = {
      data: {
        results: [],
        status: "fetching",
        errors: {}
      }
    };
  },
  [constants.ITEM_GET_LIST_BY_FACET_SUCCESS]: (state, { response, params }) => {
    state.by[params.facet] = {
      data: {
        results: response.data.results,
        status: "success",
        errors: {}
      }
    };
  },
  [constants.ITEM_GET_LIST_BY_FACET_ERROR]: (state, { err, params }) => {
    state.by[params.facet] = {
      data: {
        results: [],
        status: "success",
        errors: err
      }
    };
  },
  [constants.GET_THEME_ITEMS_BY_FACET]: (state, params) => {
    state.themesBy[params.theme_uid] = {
      data: {
        results: [],
        status: "fetching",
        errors: {}
      }
    };
  },
  [constants.GET_THEME_ITEMS_BY_FACET_SUCCESS]: (
    state,
    { response, params }
  ) => {
    state.themesBy[params.theme_uid] = {
      data: {
        ...response.data,
        status: "success",
        errors: {}
      }
    };
  },
  [constants.GET_THEME_ITEMS_BY_FACET_ERROR]: (state, { err, params }) => {
    state.themesBy[params.theme_uid] = {
      data: {
        results: [],
        status: "success",
        errors: err
      }
    };
  },
  [constants.ITEM_SET_FILTER_PARAMS]: (state, params) => {
    // if reset et fields are passed
    if (params.reset && params.fields) {
      state.filterParams = {
          age_groups: null,
          categories: null,
          courses: null,
          search: null,
          show: null,
          group_slug: null,
          group_type: null,
          ordering: "-published_on",
          page: 1,
          page_size: null,
          ...params.fields
      };
      return;
    }
    // if only reset is passaed
    else if (params.reset && !params.fields) {
      state.filterParams = {
          age_groups: null,
          categories: null,
          courses: null,
          search: null,
          show: null,
          group_slug: null,
          group_type: null,
          ordering: "-published_on",
          page: 1,
          page_size: null
      };
      return;
    }
    // if only fields is passed
    else if (params.fields && !params.reset) {
      state.filterParams = {
        ...state.filterParams,
        ...params.fields
      };
      return;
    }
  },
  [constants.ITEM_SET_USER_FILTER]: (state, params) => {
    // if reset et fields are passed
    if (params.reset && params.fields) {
      state.userFilters = {
        age_groups: null,
        categories: null,
        published_on: null,
        courses: null,
        closed_captions: null,
        curricula: null,
        language: null,
        item_type: null,
        subjects: null,
        show: null,
        channels: null,
        ...params.fields
      };
      return;
    }
    // if only reset is passed
    else if (params.reset && !params.fields) {
      state.userFilters = {
        age_groups: null,
        categories: null,
        published_on: null,
        courses: null,
        closed_captions: null,
        curricula: null,
        language: null,
        item_type: null,
        subjects: null,
        show: null,
        channels: null
      };
      return;
    }
    // if only fields is passed
    else if (params.fields && !params.reset) {
      state.userFilters = {
        ...state.userFilters,
        ...params.fields
      };
      return;
    }
  },
  [constants.GET_AGE_GROUPS]: (state) => {
    state.ageGroups.status = "fetching";
    state.ageGroups.errors = {};
  },
  [constants.GET_AGE_GROUPS_SUCCESS]: (state, { data }) => {
    state.ageGroups.status = "success";
    state.ageGroups.errors = {};
    state.ageGroups.data = data.results;
  },
  [constants.GET_AGE_GROUPS_ERROR]: (state, err) => {
    state.ageGroups.status = "error";
    state.ageGroups.errors = err;
  },
  [constants.GET_TEAM_AGE_GROUPS]: (state) => {
    state.teamAgeGroups.status = "fetching";
    state.teamAgeGroups.errors = {};
  },
  [constants.GET_TEAM_AGE_GROUPS_SUCCESS]: (state, { data }) => {
    state.teamAgeGroups.status = "success";
    state.teamAgeGroups.errors = {};
    state.teamAgeGroups.data = data.results;
  },
  [constants.GET_TEAM_AGE_GROUPS_ERROR]: (state, err) => {
    state.teamAgeGroups.status = "error";
    state.teamAgeGroups.errors = err;
  },
  [constants.GET_ITEMS_AUTOCOMPLETE]: (state) => {
    state.auto.status = "fetching";
    state.auto.errors = {};
  },
  [constants.GET_ITEMS_AUTOCOMPLETE_SUCCESS]: (state, { data }) => {
    state.auto.status = "success";
    state.auto.errors = {};
    state.auto.data = data;
  },
  [constants.GET_ITEMS_AUTOCOMPLETE_ERROR]: (state, err) => {
    state.auto.status = "error";
    state.auto.errors = err;
  },
  [constants.SET_AUTOCOMPLETE_QUERY]: (state, value) => {
    state.auto.q = value;
  },
  [constants.SET_AUTOCOMPLETE_SELECTED]: (state, value) => {
    state.auto.selected = value;
  },
  [constants.ITEMS_APPEND_NEXT]: (state) => {
    state.list.status = `fetching`;
    state.list.errors = {};
  },
  [constants.ITEMS_APPEND_NEXT_SUCCESS]: (state, { data }) => {
    state.list.status = `success`;
    state.list.errors = {};
    state.list.data.results = [...state.list.data.results, ...data.results];
    state.list.data.count = data.count;
    state.list.data.next = data.next;
    state.list.data.current = data.current;
    state.list.data.next_page_number = data.next_page_number;
  },
  [constants.ITEMS_APPEND_NEXT_ERROR]: (state, err) => {
    state.list.status = `error`;
    state.list.errors = err.response ? err.response.data : err;
  },
  [constants.ITEMS_APPEND_NEXT_THEMES]: (state) => {
    state.themes.status = `fetching`;
    state.themes.errors = {};
  },
  [constants.ITEMS_APPEND_NEXT_THEMES_SUCCESS]: (state, { data }) => {
    state.themes.status = `success`;
    state.themes.errors = {};
    state.themes.data.results = [...state.themes.data.results, ...data.results];
    state.themes.data.count = data.count;
    state.themes.data.next = data.next;
    state.themes.data.current = data.current;
    state.themes.data.next_page_number = data.next_page_number;
  },
  [constants.ITEMS_APPEND_NEXT_THEMES_ERROR]: (state, err) => {
    state.themes.status = `error`;
    state.themes.errors = err.response ? err.response.data : err;
  },
  [constants.ITEM_GET_AGGS]: (state) => {
    state.aggs.status = "fetching";
    state.aggs.errors = {};
  },
  [constants.ITEM_GET_AGGS_SUCCESS]: (state, { data }) => {
    state.aggs.status = "success";
    state.aggs.errors = {};
    state.aggs.data = data;
  },
  [constants.ITEM_GET_AGGS_ERROR]: (state, err) => {
    state.aggs.status = "error";
    state.aggs.errors = err;
  },
  [constants.ITEM_SET_AGGS_FILTER]: (state, params) => {
    state.aggs.filters = {
      ...params
    };
  },
  [constants.GET_CHANNEL]: (state) => {
    state.channel.status = "fetching";
    state.channel.errors = {};
  },
  [constants.GET_CHANNEL_SUCCESS]: (state, { data }) => {
    state.channel.status = "success";
    state.channel.errors = {};
    state.channel.data = data;
  },
  [constants.GET_CHANNEL_ERROR]: (state, err) => {
    state.channel.status = "error";
    state.channel.errors = err;
  },
  [constants.GET_CHANNELS]: (state) => {
    state.channels.status = "fetching";
    state.channels.errors = {};
  },
  [constants.GET_CHANNELS_SUCCESS]: (state, { data }) => {
    state.channels.status = "success";
    state.channels.errors = {};
    state.channels.data = data;
  },
  [constants.GET_CHANNELS_ERROR]: (state, err) => {
    state.channels.status = "error";
    state.channels.errors = err;
  },
  [constants.GET_CHANNEL_ITEMS]: (state, params) => {
    state.by.channels[params.uid] = {
      data: {
        results: [],
        status: "fetching",
        errors: {}
      }
    };
  },
  [constants.GET_CHANNEL_ITEMS_SUCCESS]: (state, { response, params }) => {
    state.by.channels[params.uid] = {
      data: {
        ...response.data,
        status: "success",
        errors: {}
      }
    };
  },
  [constants.GET_CHANNEL_ITEMS_ERROR]: (state, { err, params }) => {
    state.by.channels[params.uid] = {
      data: {
        results: [],
        status: "success",
        errors: err
      }
    };
  },
  [constants.GET_THEMES]: (state) => {
    state.themes.status = "fetching";
    state.themes.errors = {};
  },
  [constants.GET_THEMES_SUCCESS]: (state, { data }) => {
    state.themes.status = "success";
    state.themes.errors = {};
    state.themes.data = data;
  },
  [constants.GET_THEMES_ERROR]: (state, err) => {
    state.themes.status = "error";
    state.themes.errors = err;
  },
  [constants.GET_THEME]: (state) => {
    state.theme.status = "fetching";
    state.theme.errors = {};
  },
  [constants.GET_THEME_SUCCESS]: (state, { data }) => {
    state.theme.status = "success";
    state.theme.errors = {};
    state.theme.data = data;
  },
  [constants.GET_THEME_ERROR]: (state, err) => {
    state.theme.status = "error";
    state.theme.errors = err;
  },
  [constants.GET_GROUP]: (state) => {
    state.group.status = "fetching";
    state.group.errors = {};
    state.group.data.results = [];
  },
  [constants.GET_GROUP_SUCCESS]: (state, { data }) => {
    state.group.status = "success";
    state.group.errors = {};
    state.group.data = data;
  },
  [constants.GET_GROUP_ERROR]: (state, err) => {
    state.group.status = "error";
    state.group.errors = err;
  },
  [constants.GET_GROUP_APPEND_NEXT]: (state) => {
    state.group.status = "fetching";
    state.group.errors = {};
  },
  [constants.GET_GROUP_APPEND_NEXT_SUCCESS]: (state, { data }) => {
    state.group.status = "success";
    state.group.errors = {};
    state.group.data.results = [...state.group.data.results, ...data.results];
    state.group.data.count = data.count;
    state.group.data.next = data.next;
    state.group.data.current = data.current;
    state.group.data.next_page_number = data.next_page_number;
  },
  [constants.GET_GROUP_APPEND_NEXT_ERROR]: (state, err) => {
    state.group.status = "error";
    state.group.errors = err;
  },
  [constants.SET_ITEM_SCREENER_ISPLAYING]: (state, stat) => {
    state.stats.screenerIsPlaying = stat;
  },
  [constants.SET_ITEM_DETAIL]: (state, item) => {
    state.detail.status = "success";
    state.detail.errors = {};
    state.detail.data = item;
  },
  [constants.SET_ITEM_INITIAL_COUNT]: (state, { response, filters }) => {
    if (!Object.keys(filters).length) {
      state.list.initialCount =
        response.data?.count || response?.data?.lineup_items?.length;
    }
  },
  [constants.SET_UP_NEXT_LIST]: (state, list) => {
    state.upNext.data.results = list;
  },
  [constants.TRACK_UP_NEXT]: (state) => {
    state.upNext.track = true;
  },
  [constants.GET_JSON_LD]: (state) => {
    state.seo.status = "fetching";
    state.seo.errors = {};
    state.seo.data = {};
  },
  [constants.GET_JSON_LD_SUCCESS]: (state, data) => {
    state.seo.status = "success";
    state.seo.errors = {};
    state.seo.data = data;
    document.title = data.name + i18n.global.t("pageMeta.default.endTitle");
  },
  [constants.GET_JSON_LD_ERROR]: (state, err) => {
    state.seo.status = "error";
    state.seo.errors = err;
  },

  ["RESET"]: (state) => {
    const defaultState = getDefaultState();
    Object.keys(defaultState).forEach((key) => {
      state[key] = defaultState[key];
    });
  }
};

export default {
  state: getDefaultState(),
  getters,
  actions,
  mutations
};
