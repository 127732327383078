import axios from "@/libvue/api/axios.js";
import * as constants from "./actions";

function getDefaultState() {
  return {
    detail: {
      data: {},
      status: "",
      errors: {}
    },
    signedURL: {
      data: {},
      status: "",
      errors: {}
    }
  };
}

const actions = {
  [constants.GET_SCREENER]: ({ commit }, uid) => {
    return new Promise((resolve, reject) => {
      commit(constants.GET_SCREENER);
      axios
        .get(`screeners/${uid}`)
        .then((response) => {
          commit(constants.GET_SCREENER_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_SCREENER_ERROR, error);
          reject(error);
        });
    });
  },

  [constants.GET_SIGNED_URL]: ({ commit }, uid) => {
    return new Promise((resolve, reject) => {
      commit(constants.GET_SIGNED_URL);
      axios
        .get(`medias/${uid}/signed-m3u8`)
        .then((response) => {
          commit(constants.GET_SIGNED_URL_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_SIGNED_URL_ERROR, error);
          reject(error);
        });
    });
  },
};

const mutations = {
  [constants.GET_SCREENER]: (state) => {
    state.detail.status = "fetching";
    state.detail.errors = {};
  },
  [constants.GET_SCREENER_SUCCESS]: (state, { data }) => {
    state.detail.status = "success";
    state.detail.errors = {};
    state.detail.data = data;
  },
  [constants.GET_SCREENER_ERROR]: (state, err) => {
    state.detail.status = "error";
    state.detail.errors = err;
  },
  [constants.GET_SIGNED_URL]: (state) => {
    state.signedURL.status = "fetching";
    state.signedURL.errors = {};
  },
  [constants.GET_SIGNED_URL_SUCCESS]: (state, { data }) => {
    state.signedURL.status = "success";
    state.signedURL.errors = {};
    state.signedURL.data = data;
  },
  [constants.GET_SIGNED_URL_ERROR]: (state, err) => {
    state.signedURL.status = "error";
    state.signedURL.errors = err;
  },
  ["RESET"]: (state) => {
    const defaultState = getDefaultState();
    Object.keys(defaultState).forEach((key) => {
      state[key] = defaultState[key];
    });
  }
};

export default {
  state: getDefaultState(),
  actions,
  mutations
};
